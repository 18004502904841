.evp-exp {
  &__col {
    &_spaze, &_sehack {
      picture {
        width: 45%;
      }
    }
  }

  &__box {
    padding-bottom: 68% !important;
  }

  &__content {
    &-title {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
