$header-height: 30%;
$header-profile-height: 10.5rem;

.evp-header {
  height: $header-height;
  max-height: $header-height;
  padding-top: 1.5rem;
}

.evp-profile {
  max-height: $header-profile-height;
  width: 100%;

  &__locals {
    top: -1.2rem;
  }

  &__avatar {
    $height: 9rem;
    $width: 7.4rem;

    height: $height;
    position: relative;
    width: $width;

    &:before {
      background-size: $height * 0.895;
      height: $height;
      top: -.2rem;
      width: $width;
    }
  }

  &__title {
    span {
      font-size: 1rem;
    }
  }

  &__info {
    background-size: 100%;
    height: 2.2rem;
    width: 10rem;
  }

  &-links__skills {
    span {
      font-size: .9rem;
    }
  }
}
