$player-height: 10rem;

.evp-player {
  height: $player-height;

  &__control {
    width: 42%;
  }

  &__info {
    width: 45%;
  }

  &__embed {
    transform: translate(0, $player-height * -1) !important;
  }
}
