@import "../variables";
$prefix: 'bs-';
$font-size-root: 16px;

:root {
  --#{$prefix}root-font-size: #{$font-size-root};
}


@import "header-xs";
@import "../md/experience-md";
@import "../sm/experience-sm";
@import "hobbies-xs";
@import "experience-xs";
@import "../md/player-md";
@import "player-xs";

.evp-main {
  height: 70%;

  .row > * {
    box-sizing: border-box;
    padding-right: .25rem;
    padding-left: .25rem;
  }
}

.evp-btn {
  span {
    font-size: 1rem;
  }
}
